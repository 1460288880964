import React, { Component } from 'react';
import { Grid, Button, Divider, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ELfondLogo from '../style/img/el_regionaalarengu_fond_horisontaalne.png';

import { translate } from '../utils.js';
import LanguageContext from './LanguageContext';

const UserPDFest = "/inaadress/js/pdf/et/in_aadress_manual.pdf"
const UserPDFeng = "/inaadress/js/pdf/en/in_aadress_manual.pdf"
const DevPDFest = "/inaadress/js/pdf/et/in_aadress_developer_manual.pdf"
const DevPDFeng = "/inaadress/js/pdf/en/in_aadress_developer_manual.pdf"

class Intro extends Component {


    //using this.context will return the currently selected language code (eg. "et" or "en")
    static contextType = LanguageContext;

    componentDidMount() {
        document.body.appendChild(this.createScript());
    }

    createScript() {
        const script = document.createElement("script");
        script.id = "inAdsScript"
        script.text = 'var inAadress = new InAadress({"container":"InAadressDiv","mode":"1","searchLayers":["EHAK","VAIKEKOHT","KATASTRIYKSUS","TANAV","EHITISHOONE"],"appartment":1,"ihist":"1993","defaultBaseLayer":"ALUSKAART","baseLayers":["ALUSKAART"],"lang":"' + this.context + '","hideLogo":true});'
        this.setState({ script: script })
        return script
    }

    //cleans up the DOM from the added scripts
    cleanUpScript() {
        document.body.removeChild(this.state.script)
    }

    componentWillUnmount() {
        this.cleanUpScript()
    }

    render() {
        return (
            <div>
                <div className="heading">
                    <Divider horizontal>{translate("Intro", "title", this.context)}</Divider>
                </div>

                <Grid stackable centered columns={2}>
                    <Grid.Row>
                        <Grid.Column className="kaart">
                            <div id="InAadressDiv" />
                            <div id="fundLogo2">
                                <img src={ELfondLogo} alt="EL regionaalarengu fond logo" />
                            </div>
                        </Grid.Column>

                        <Grid.Column className="introText">
                            <p>{translate("Intro", "introparagraph1", this.context)}</p>
                            <p>{translate("Intro", "introparagraph2", this.context)}</p>
                            <p>{translate("Intro", "introparagraph3", this.context)}</p>
                            <Grid.Column className="instructions">
                                <div>
                                    <a className="manual ui-link" rel="noopener noreferrer" target="_blank"
                                        href={this.context === "et" ?
                                            UserPDFest
                                            :
                                            UserPDFeng}><Icon name="book" />{translate("Intro", "usermanual", this.context)}</a><br></br>
                                    <a className="devmanual ui-link" rel="noopener noreferrer" target="_blank"
                                        href={this.context === "et" ?
                                            DevPDFest
                                            :
                                            DevPDFeng}><Icon name="book" /> {translate("Intro", "devmanual", this.context)}</a><br></br>
                                    <a className="terms ui-link" rel="noopener noreferrer" target="_blank" href={this.context === "et" ? "https://geoportaal.maaamet.ee/docs/aadress/In-ADS_kasutustingimused.pdf" : "https://geoportaal.maaamet.ee/docs/aadress/In-ADS_terms_of_use.pdf"}><Icon name="info" /> {translate("Intro", "termsandconditions", this.context)}</a><br></br>
                                    <a className="maillist ui-link" rel="noopener noreferrer" target="_blank" href="https://geoportaal.maaamet.ee/est/Teenused/X-tee-teenused/Aadressiandmete-susteemi-X-tee-teenused/ADS-ja-In-ADS-meililistiga-liitumise-taotlus-p632.html"><Icon name="mail" />{translate("Intro", "maillist", this.context)}</a>
                                </div>

                                <div id="fundLogo3">
                                    <img src={ELfondLogo} alt="EL regionaalarengu fond logo" />
                                </div>
                            </Grid.Column>
                        </Grid.Column>

                    </Grid.Row>

                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Grid.Row className="button">
                                <Button as={Link} to="/S1" className="ui animated button" tabIndex="0">
                                    <div className="visible content">{translate("Buttons", "generate", this.context)}</div>
                                    <div className="hidden content">
                                        <i className="right arrow icon"></i>
                                    </div>
                                </Button>
                            </Grid.Row>

                            <Grid.Row className="button">
                                <Button as={Link} to="/S3e" className="ui animated button" tabIndex="0">
                                    <div className="visible content">{translate("Buttons", "generatecompany", this.context)}</div>
                                    <div className="hidden content">
                                        <i className="right arrow icon"></i>
                                    </div>
                                </Button>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default Intro