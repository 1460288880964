import React, { Component } from 'react'
import { Grid, Divider } from 'semantic-ui-react';

import { generateCode } from './CodeGeneratorResult'
import NavButton from './NavButton';

import LanguageContext from './LanguageContext';
import { translate } from '../utils';

export class LocationDisplayPreview extends Component {

    //using this.context will return the currently selected language code (eg. "et" or "en")
    static contextType = LanguageContext;

    componentDidMount() {
        document.body.appendChild(this.createScript());

        //make this the last page visited before CodeGeneratorResult.js so it could be went back to
        this.props.handler("pageBeforeResult", "/S4e2")
    }

    createScript() {
        const script = document.createElement("script");
        script.text = generateCode(this.props.choicesMade, this.context, true)
        this.setState({ script: script })
        return script
    }

    //cleans up the DOM from the added scripts
    cleanUpScript() {
        document.body.removeChild(this.state.script)
    }

    componentWillUnmount() {
        this.cleanUpScript()
    }

    render() {
        return (
            <div>
                <div className="heading">
                    <Divider horizontal>{translate("LocationDisplayPreview", "title", this.context)}</Divider>
                </div>
                <div className="example">
                    <div id={this.props.choicesMade.container} style={{ width: '' + this.props.choicesMade.width + 'px', height: '' + this.props.choicesMade.height + 'px' }}></div>
                </div>

                <Grid centered columns={2}>
                    <Grid.Row className="buttonRow button">
                        <Grid.Column>
                            <NavButton whereto={"/S4e"} icon="arrow left" text="back" />
                        </Grid.Column>
                        <Grid.Column>
                            <NavButton whereto={"/S4"} icon="arrow right" text="generate" />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

export default LocationDisplayPreview
