import React, { Component } from 'react'
import fetchJsonp from 'fetch-jsonp'

import { Grid, Divider, Checkbox, Dropdown, Input, Button, List, Label, Icon } from 'semantic-ui-react';

import NavButton from './NavButton';

import LanguageContext from './LanguageContext';
import { translate } from '../utils';

export class MapTypeChoice extends Component {

	//using this.context will return the currently selected language code (eg. "et" or "en")
	static contextType = LanguageContext;

	constructor(props) {
		super(props);
		this.state = { "loaded": false, displayedWMSFilters: this.loadWMSFilters(this.props.choicesMade.wms), omavalitsus: null, inputField: "" }
	}

	//fill the displayed filters list when mounting the component
	componentDidMount() {
		//if this is the first time rendering this component, then load the counties
		if (this.state.counties === undefined) {
			//fetch the counties
			let ehakPath;
			if (window.REACT_APP_INADS_PATH_BASE_URL === "") {
				ehakPath = window.REACT_APP_EHAK_FETCH_URL
			}
			else {
				ehakPath = window.REACT_APP_INADS_PATH_BASE_URL + window.REACT_APP_EHAK_FETCH_URL
			}

			fetchJsonp(ehakPath)
				.then(
					(response) => {
						response.json().then(data => ({
							data: data,
						})).then(result => {
							//sort the data
							result.data.sort(this.compareEhak)
							//add the first default option
							result.data.unshift({ "name": translate("MapTypeChoice", "selectcounty", this.context) })

							//set state variable "loaded" to true, and trigger rerender
							this.setState({ "counties": result.data, "dropdownList": this.createDropdownListElements(result.data), "loaded": true })
						})
					},
					(error) => {
						console.log(error);
					}
				)
		}

		if (this.props.display.ehakCounty !== "-1") { //there has been a county selected
			//fetch the local govs
			let ehakPath;
			if (window.REACT_APP_INADS_PATH_BASE_URL === "") {
				ehakPath = window.REACT_APP_EHAK_FETCH_URL
			}
			else {
				ehakPath = window.REACT_APP_INADS_PATH_BASE_URL + window.REACT_APP_EHAK_FETCH_URL
			}
			fetchJsonp(ehakPath + this.props.display.ehakCounty.replace(/^0+/, ''))
				.then(
					(response) => {
						response.json().then(data => ({
							data: data,
						})).then(result => {
							//sort the data
							result.data.sort(this.compareEhak)
							//Omavalitsus sub list assignment
							var dropdownSubList = [{ key: "0", text: translate("MapTypeChoice", "selectlocalgov", this.context), value: "-1" }]
							let i = 1 //key counter

							//Iter omavalitsused, create an object with appropriate data and push it to the omavalitsus list
							for (let el of result.data) {
								var add = {
									key: String(i),
									text: el.name,
									value: el.kood
								}
								dropdownSubList.push(add)
								i += 1
							}

							this.setState({ "omavalitsus": dropdownSubList, "loaded2": true })
						})
					},
					(error) => {
						console.log(error);
					}
				)
		}

		//make this the last page visited before CodeGeneratorResult.js so it could be went back to
		this.props.handler("pageBeforeResult", "/S3")
	}

	//Function that loads the wms filters as displayable elements
	loadWMSFilters(wmsList) {
		let displayedWMSFilters = []

		if (wmsList.length === 0) return []

		let i = 0
		for (let el of wmsList) {
			displayedWMSFilters.push(
				<List.Item key={i} > {el}
					<Label keyprop={i} onClick={(_e, data) => this.handleFilterListRemove(data.keyprop)}>
						<Icon name="delete" />
					</Label>
				</List.Item>
			)

			i += 1
		}
		return displayedWMSFilters
	}

	//Helper function after fetching data
	createDropdownListElements(ehaklist) {
		//iter the items and create List elements, push them all into one list and set it as state variable

		const listItems = []
		var kood

		for (let i = 0; i < ehaklist.length; i++) {
			//for the default value, set code to "-1"
			if (ehaklist[i].kood === undefined) kood = "-1"
			else kood = ehaklist[i].kood

			var item = {
				key: String(i),
				text: ehaklist[i].name,
				value: kood
			}
			listItems.push(item)
		}
		return listItems
	}

	returnFirstLayerWithTrueValue(list) {
		for (let layer of list) {
			if (layer[1]) {
				return layer[0]
			}
		}
	}

	//Helper function for this component's checkbox functionality
	enableDisableMapBackground(position) {
		var result = this.props.choicesMade.baseLayers

		result[position][1] = !result[position][1]

		let currentDefault = this.props.choicesMade.defaultBackground

		//if the checkboxes are all unchecked and the user finally checks one, then set it as defaultBackground as well
		if (this.props.choicesMade.defaultBackground === undefined) {
			this.props.handlerInAds("defaultBackground", this.returnFirstLayerWithTrueValue(result))
		}

		for (let layer of result) {
			if (layer[0] === currentDefault) {
				//if the currentDefault is not disabled, find a new layer to set as default
				if (!layer[1]) {
					this.props.handlerInAds("defaultBackground", this.returnFirstLayerWithTrueValue(result))
				}
			}
		}

		//update the render
		this.forceUpdate()
	}

	handleDropdownSelectChange = (_e, { value }) => this.props.handlerInAds("defaultBackground", value)

	handleInputChange = (_e, { value }) => this.setState({ "inputField": value })

	handleFilterListRemove(id) {
		//also filter out the filter from the "wms" state list
		for (let el of this.state.displayedWMSFilters) {
			if (Number(el.key) === id) {
				this.state.displayedWMSFilters.splice(this.state.displayedWMSFilters.indexOf(el), 1)
				//el.props.children[0] or el.props.children[1] returns the string value of the el and then it is used to get the index of the string element in "wms" state list
				//the matched element is then removed from the wms state list
				//if the component has not been rerendered, then the string value of the el is on the 0th index
				//if the component has been rerendered, then the string value of the le is on the 1st index
				if (el.props.children[0] === " ") {
					this.props.choicesMade.wms.splice(this.props.choicesMade.wms.indexOf(el.props.children[1]), 1)
				} else {
					this.props.choicesMade.wms.splice(this.props.choicesMade.wms.indexOf(el.props.children[0]), 1)
				}

				break;
			}
		}

		//update the displayedWMSFilters list
		this.forceUpdate()

	}

	//handles the add next/lisa järgmine button press
	handleButtonPress() {
		//only add the filter if the inputField is not empty
		if (this.state.inputField !== "") {
			//Add the new filter
			this.props.choicesMade.wms.push(this.state.inputField)
			//Add the filter to the displayed filters list
			let currentList = this.state.displayedWMSFilters

			currentList.push(
				<List.Item key={this.props.choicesMade.wms.length} >{this.state.inputField}
					<Label keyprop={this.props.choicesMade.wms.length} onClick={(_e, data) => this.handleFilterListRemove(data.keyprop)}>
						<Icon name="delete" />
					</Label>
				</List.Item>
			)
			this.setState({ "displayedWMSFilters": currentList })

			//clean the input field
			this.setState({ "inputField": "" })
		}
	}

	handleCountySelect(passedData) {
		this.props.handlerInAds("ehakFilter", passedData);
		this.props.handler("ehakCounty", passedData)
		this.props.handler("ehakOV", "-1") //reset OV
		//fetch the data only when a county is selected
		if (passedData !== "-1") {
			let ehakPath;
			if (window.REACT_APP_INADS_PATH_BASE_URL === "") {
				ehakPath = window.REACT_APP_EHAK_FETCH_URL
			}
			else {
				ehakPath = window.REACT_APP_INADS_PATH_BASE_URL + window.REACT_APP_EHAK_FETCH_URL
			}
			fetchJsonp(ehakPath + passedData.replace(/^0+/, '')) //remove the first zeroes with regex
				.then(
					(response) => {
						response.json().then(data => ({
							data: data,
						})).then(result => {
							//sort the data
							result.data.sort(this.compareEhak)
							//Omavalitsus sub list assignment
							var dropdownSubList = [{ key: "0", text: translate("MapTypeChoice", "selectlocalgov", this.context), value: "-1" }]
							let i = 1 //key counter

							//Iter omavalitsused, create an object with appropriate data and push it to the omavalitsus list
							for (let el of result.data) {
								var add = {
									key: String(i),
									text: el.name,
									value: el.kood
								}
								dropdownSubList.push(add)
								i += 1
							}

							this.setState({ "omavalitsus": dropdownSubList })
						})
					},
					(error) => {
						console.log(error);
					}
				)
		}
	}

	handleOVSelect(passedData) {
		this.props.handlerInAds("ehakFilter", passedData)
		this.props.handler("ehakOV", passedData)
	}

	//sorting function
	compareEhak(a, b) {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	}

	renderOmavalitsus() {
		//Only render this when omavalitsus state variable has data
		if ((this.state.omavalitsus && this.props.display.ehakCounty !== "-1")) {
			//return the element if omavalitsus has data
			return <Dropdown
				value={this.props.display.ehakOV}
				fluid
				selection
				options={this.state.omavalitsus}
				onChange={(e, data) => { e.target.textContent === "-" ? this.props.handlerInAds("ehakFilter", "-1") : this.handleOVSelect(data.value) }}
			/>
		} else {
			return null
		}
	}

	//dynamically render the checkboxes based on the fetched baseLayers from Gazetteer
	renderBaseLayerCheckboxes() {
		if (this.props.choicesMade.baseLayers) {
			var layers = this.props.choicesMade.baseLayers
			var checkboxes = []
			for (let i = 0; i < layers.length; i++) {
				checkboxes.push(<Checkbox
					key={i}
					label={translate("MapTypeChoice", layers[i][0], this.context)}
					checked={layers[i][1]}
					onChange={() => this.enableDisableMapBackground(i)}
				/>)
			}
			return checkboxes
		}
		else return []
	}

	//check if at least one base map is selected
	atleastOneSelected() {
		for (let el of this.props.choicesMade.baseLayers) {
			if (el[1]) {
				return true
			}
		}
		return false
	}

	render() {
		//Only render the component if data is loaded
		if (!this.state.loaded) {
			return <div />
		}

		//list for the dropdown
		const backgrounds = []

		//add options to the list, which are turned on by the checkboxes
		for (let el of this.props.choicesMade.baseLayers) {
			if (el[1]) {
				var add = {
					key: el[0],
					text: translate("MapTypeChoice", el[0], this.context),
					value: el[0]
				}
				backgrounds.push(add)
			}
		}

		return (
			<div>
				<div className="heading">
					<Divider horizontal>{translate("MapTypeChoice", "title", this.context)}</Divider>
				</div>
				<Grid centered columns={2} stackable>
					<Grid.Row>
						<Grid.Column>
							<h4>{translate("MapTypeChoice", "mapbackgroundtitle", this.context)}</h4>
							<p>{translate("MapTypeChoice", "mapbackgroundtext", this.context)}</p>

							<div className="checkboxGroup">
								<div className={this.atleastOneSelected() ? "group" : "group_attention"}>
									{this.renderBaseLayerCheckboxes()}
								</div>
							</div>

							<p>{translate("MapTypeChoice", "defaultbackground", this.context)}</p>
							<Dropdown
								value={this.props.choicesMade.defaultBackground}
								fluid
								selection
								options={backgrounds}
								onChange={this.handleDropdownSelectChange}
							/>
							<h4>{translate("MapTypeChoice", "ehak", this.context)}</h4>
							<p>{translate("MapTypeChoice", "ehaktext", this.context)}</p>
							<Dropdown
								value={this.props.display.ehakCounty}
								fluid
								selection
								options={this.state.dropdownList}
								onChange={(e, data) => { e.target.textContent === "-" ? this.props.handlerInAds("ehakFilter", "-1") : this.handleCountySelect(data.value) }}
							/>

							{this.renderOmavalitsus()}
						</Grid.Column>

						<Grid.Column>
							<h4>{translate("MapTypeChoice", "maplayerchoicetitle", this.context)}</h4>
							<p>{translate("MapTypeChoice", "maplayerchoicetext", this.context)}</p>

							<div className="checkboxGroup">
								<div className="group">
									<Checkbox
										label={translate("MapTypeChoice", "addresses", this.context)}
										checked={this.props.choicesMade.mapLayersAddress}
										onChange={() => this.props.handlerInAds("mapLayersAddress", !this.props.choicesMade.mapLayersAddress)}
									/>
									<Checkbox
										label={translate("MapTypeChoice", "cadastral", this.context)}
										checked={this.props.choicesMade.mapLayersCadastral}
										onChange={() => this.props.handlerInAds("mapLayersCadastral", !this.props.choicesMade.mapLayersCadastral)}
									/>
								</div>
							</div>

							<h4>{translate("MapTypeChoice", "wmstitle", this.context)}</h4>
							<p>{translate("MapTypeChoice", "wmstext", this.context)}</p>

							<div className="inputField">
								<Input onChange={this.handleInputChange} />
								<Button
									onClick={() => this.handleButtonPress()}
								>{translate("MapTypeChoice", "addbtn", this.context)}</Button>
							</div>

							<p>{this.props.choicesMade.wms.length !== 0 ? translate("MapTypeChoice", "addedlayers", this.context) : null}</p>

							<div className="filterList">
								<List bulleted>
									{this.state.displayedWMSFilters}
								</List>
							</div>

						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Grid centered columns={2}>
					<Grid.Row className="buttonRow button">
						<Grid.Column>
							<NavButton whereto={"/S2"} icon="arrow left" text="back" />
						</Grid.Column>
						<Grid.Column>
							{this.atleastOneSelected() ?
								<NavButton whereto={"/S4"} icon="arrow right" text="next" />
								:
								<Button className="dummy" animated="fade">
									<Button.Content visible>{translate("Buttons", "next", this.context)}</Button.Content>
									<Button.Content hidden>
										<Icon name="attention" />
									</Button.Content>
								</Button>
							}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		)
	}
}

export default MapTypeChoice
