import React, { Component } from 'react';
import { Grid, Form, Radio, Divider, Icon, List, Checkbox, Dropdown, Button } from 'semantic-ui-react';
import NavButton from './NavButton';

import LanguageContext from './LanguageContext';
import { translate } from '../utils';
import * as poi_and_knr_types from '../helperJSONs/poi_and_knr_types.json'

class SearchTypeChoice extends Component {
    componentDidMount() {
        let i = 0
        var initialRenderingOfPrios = []
        for (let prio of this.props.display.priority) {
            if (prio[1]) initialRenderingOfPrios.push(<List.Item key={i} >{translate("Priorities", prio[0], this.context)}</List.Item>)
            i += 1
        }
        this.props.handler("displayedPriorities", initialRenderingOfPrios)

        if (this.props.choicesMade.mode === 3) {
            this.resetChoicesWithoutApartment()
            this.props.handlerInAds("search", 1)
        }
    }

    //using this.context will return the currently selected language code (eg. "et" or "en")
    static contextType = LanguageContext;

    //resets the values of some checkboxes (except apartment and allownewApartments) when the general address based search radio button is selected
    resetChoicesWithoutApartment() {
        this.props.handlerInAds("ehak", false);
        this.props.handlerInAds("territorial", false);
        this.props.handlerInAds("cadastral", false);
        this.props.handlerInAds("traffic", false);


        this.props.handlerInAds("building", false);
        this.props.handlerInAds("buildingUN", false);
        this.props.handlerInAds("filters", false);
    }

    //resets the values of some checkboxes when the general address based search radio button is selected
    resetChoices() {
        this.props.handlerInAds("ehak", false);
        this.props.handlerInAds("territorial", false);
        this.props.handlerInAds("cadastral", false);
        this.props.handlerInAds("traffic", false);

        this.props.handlerInAds("maPiirkond", false);

        this.props.handlerInAds("building", false);
        this.props.handlerInAds("buildingUN", false);
        this.props.handlerInAds("filters", false);
        this.props.handlerInAds("selectBuilding", false)
        this.props.handlerInAds("apartment", false);
        this.props.handlerInAds("allowNewApartments", false)
    }

    //resets the values of building-specific choices when unchecking the building checkbox
    resetBuildingChoices() {
        this.props.handlerInAds("building", !this.props.choicesMade.building);
        this.props.handlerInAds("buildingUN", false);
        this.props.handlerInAds("apartment", false);
        this.props.handlerInAds("allowNewApartments", false)
    }

    //rerenderes priorities
    //when a rule is turned off, then this method needs to be called to display the updated priority rules' list
    reRenderPriorities() {
        var render = []
        let i = 0
        for (let el of this.props.display.priority) {
            //only add it to the list when the priority rule is "turned on" aka el[1] === true
            if (el[1]) {
                render.push(<List.Item key={i} >{translate("Priorities", el[0], this.context)}</List.Item>)
            }
            i += 1
        }
        this.props.handler("displayedPriorities", render)
    }

    //turns on every priority, displaying the whole list of them
    resetPriorities() {
        for (let el of this.props.display.priority) {
            el[1] = true
        }
        this.reRenderPriorities()
    }

    //empties the priority list, turns off every priority
    emptyPriorities() {
        for (let el of this.props.display.priority) {
            el[1] = false
        }
        this.reRenderPriorities()
    }

    //disables priorities based on passed in list of rules' positions
    //[0,5,6] "disable" the 1st, 6th and 7th rule -> BASICALLY does this: this.props.choicesMade.priority[0][1] = false, this.props.choicesMade.priority[5][1] = false etc...
    disablePriorities(...positions) {
        var priorities = this.props.display.priority

        for (let position of positions) {
            priorities[position][1] = false //disable
        }
        this.reRenderPriorities()
    }

    //enables priorities based on passed in list of rules' positions
    //[0,5,6] "enable" the 1st, 6th and 7th rule -> BASICALLY does this: this.props.choicesMade.priority[0][1] = true, this.props.choicesMade.priority[5][1] = true etc...
    enablePriorities(...positions) {
        var priorities = this.props.display.priority

        for (let position of positions) {
            priorities[position][1] = true //enable
        }
        this.reRenderPriorities()
    }

    atleastOneSelected() {
        var choices = this.props.choicesMade
        return !(choices.search === 2 && !(choices.ehak || choices.territorial || choices.cadastral || choices.traffic || choices.building))
    }

    atleastOneSelectedANDPoiKnrNotEmpty() {
        var choices = this.props.choicesMade
        return !(choices.search === 2 && !(choices.ehak || choices.territorial || choices.cadastral || choices.traffic || choices.building)) && !((choices.poi || choices.knr) && choices.type.length === 0)
    }

    checkIfMode3() {
        if (this.props.choicesMade.mode === 3) {
            return <NavButton whereto={"/S4"} icon="arrow right" text="next" />
        }
        return <NavButton whereto={"/S3"} icon="arrow right" text="next" />
    }

    handleTypeClick(type, types) {
        const { choicesMade } = this.props
        const copyOfTypeInState = choicesMade.type
        if (copyOfTypeInState.includes(types[type])) {
            copyOfTypeInState.splice(copyOfTypeInState.indexOf(types[type]), 1)
        }
        else {
            copyOfTypeInState.push(types[type])
        }

        copyOfTypeInState.sort()
        this.props.handlerInAds("type", copyOfTypeInState)
    }

    enableSelectBuilding() {
        return (this.props.choicesMade.search === 2 && this.props.choicesMade.cadastral && this.props.choicesMade.building)
    }

    renderPOIandKNRGroup() {
        const { choicesMade } = this.props
        const types = poi_and_knr_types.default
        return (
            <div className={((choicesMade.poi || choicesMade.knr) && choicesMade.type.length === 0) ? "group_attention" : "group"}>
                <div className='together'>
                    <Checkbox
                        label={translate("SearchTypeChoice", "POIcheckbox", this.context)}
                        checked={choicesMade.poi}
                        onChange={this.props.handlerInAds.bind(this, "poi", !this.props.choicesMade.poi)}
                    />
                    <Checkbox
                        label={translate("SearchTypeChoice", "KNRcheckbox", this.context)}
                        checked={choicesMade.knr}
                        onChange={this.props.handlerInAds.bind(this, "knr", !this.props.choicesMade.knr)}
                    />
                </div>

                {Object.keys(types).map((type, i) => {
                    return (
                        <Checkbox
                            key={i}
                            className='tabbed'
                            label={translate("SearchTypeChoice", type + "_checkbox", this.context)}
                            disabled={!choicesMade.poi && !choicesMade.knr}
                            checked={choicesMade.type.includes(types[type])}
                            onChange={() => this.handleTypeClick(type, types)}
                        />
                    )
                })}
                <Checkbox
                    label={translate("SearchTypeChoice", "checkbox_maPiirkond", this.context)}
                    checked={this.props.choicesMade.maPiirkond}
                    disabled={!this.props.choicesMade.knr}
                    onChange={!this.props.choicesMade.maPiirkond ? () => { this.props.handlerInAds("maPiirkond", !this.props.choicesMade.maPiirkond); this.enablePriorities() } : () => { this.props.handlerInAds("maPiirkond", !this.props.choicesMade.maPiirkond); this.disablePriorities() }}
                />
            </div>
        )
    }

    renderSmallOptionsGroup(kuvaKaardilOptions) {
        return (<div className="group">
            <Checkbox
                label={translate("SearchTypeChoice", "checkbox9", this.context)}
                checked={this.props.choicesMade.filters}
                disabled={this.props.choicesMade.search === 1}
                onChange={this.props.handlerInAds.bind(this, "filters", !this.props.choicesMade.filters)}
            />
            <Checkbox
                label={translate("SearchTypeChoice", "building_if_possible", this.context)}
                checked={this.props.choicesMade.selectBuilding}
                disabled={!this.enableSelectBuilding()}
                onChange={this.props.handlerInAds.bind(this, "selectBuilding", !this.props.choicesMade.selectBuilding)}
            />
            <Checkbox
                label={translate("SearchTypeChoice", "checkbox14", this.context)}
                checked={this.props.choicesMade.displayGray}
                disabled={this.props.choicesMade.mode === 3}
                onChange={this.props.handlerInAds.bind(this, "displayGray", !this.props.choicesMade.displayGray)}
            />
            <Checkbox
                label={translate("SearchTypeChoice", "checkbox_use_help", this.context)}
                checked={this.props.choicesMade.help}
                onChange={this.props.handlerInAds.bind(this, "help", !this.props.choicesMade.help)}
            />
            <Checkbox
                label={translate("SearchTypeChoice", "ky_checkbox", this.context)}
                checked={this.props.choicesMade.ky}
                onChange={this.props.handlerInAds.bind(this, "ky", !this.props.choicesMade.ky)}
            />
            <Checkbox
                label={translate("SearchTypeChoice", "iTapp_checkbox", this.context)}
                checked={this.props.choicesMade.iTappAsendus}
                onChange={this.props.handlerInAds.bind(this, "iTappAsendus", !this.props.choicesMade.iTappAsendus)}
            />
            <Checkbox
                label={translate("SearchTypeChoice", "tech_checkbox", this.context)}
                checked={this.props.choicesMade.tech}
                onChange={this.props.handlerInAds.bind(this, "tech", !this.props.choicesMade.tech)}
            />
            <div className='text-and-combo'>
                <p>{translate("SearchTypeChoice", "display_on_map", this.context)}</p>
                <Dropdown
                    value={this.props.choicesMade.displayGeom}
                    fluid
                    selection
                    options={kuvaKaardilOptions}
                    disabled={this.props.choicesMade.mode === 3}
                    onChange={(_e, data) => this.props.handlerInAds("displayGeom", data.value)}
                />
            </div>
        </div>)
    }

    renderSearchLayerGroup() {
        return (<div className={this.atleastOneSelected() ? "group" : "group_attention"}>
            <Checkbox
                label={translate("SearchTypeChoice", "checkbox1", this.context)}
                checked={this.props.choicesMade.ehak}
                disabled={this.props.choicesMade.search === 1}
                onChange={!this.props.choicesMade.ehak ? () => { this.props.handlerInAds("ehak", !this.props.choicesMade.ehak); this.enablePriorities(0, 1, 2) } : () => { this.props.handlerInAds("ehak", !this.props.choicesMade.ehak); this.disablePriorities(0, 1, 2) }}
            />

            <Checkbox
                label={translate("SearchTypeChoice", "checkbox2", this.context)}
                checked={this.props.choicesMade.territorial}
                disabled={this.props.choicesMade.search === 1}
                onChange={!this.props.choicesMade.territorial ? () => { this.props.handlerInAds("territorial", !this.props.choicesMade.territorial); this.enablePriorities(3) } : () => { this.props.handlerInAds("territorial", !this.props.choicesMade.territorial); this.disablePriorities(3) }}
            />

            <Checkbox
                label={translate("SearchTypeChoice", "checkbox3", this.context)}
                checked={this.props.choicesMade.cadastral}
                disabled={this.props.choicesMade.search === 1}
                onChange={!this.props.choicesMade.cadastral ? () => { this.props.handlerInAds("cadastral", !this.props.choicesMade.cadastral); this.enablePriorities(11, 12) } : () => { this.props.handlerInAds("cadastral", !this.props.choicesMade.cadastral); this.disablePriorities(11, 12); this.props.handlerInAds("selectBuilding", false) }}
            />

            <Checkbox
                label={translate("SearchTypeChoice", "checkbox4", this.context)}
                checked={this.props.choicesMade.traffic}
                disabled={this.props.choicesMade.search === 1}
                onChange={!this.props.choicesMade.traffic ? () => { this.props.handlerInAds("traffic", !this.props.choicesMade.traffic); this.enablePriorities(4) } : () => { this.props.handlerInAds("traffic", !this.props.choicesMade.traffic); this.disablePriorities(4) }}
            />
            <Checkbox
                label={translate("SearchTypeChoice", "checkbox5", this.context)}
                checked={this.props.choicesMade.building}
                disabled={this.props.choicesMade.search === 1}
                onChange={this.props.choicesMade.building ? () => { this.resetBuildingChoices(); this.disablePriorities(7, 8, 13, 14); this.props.handlerInAds("selectBuilding", false) } : () => { this.props.handlerInAds("building", !this.props.choicesMade.building); this.enablePriorities(7, 8, 13, 14) }}
            />
            <Checkbox
                label={translate("SearchTypeChoice", "checkbox6", this.context)}
                checked={this.props.choicesMade.buildingUN}
                disabled={this.props.choicesMade.search === 1 || (this.props.choicesMade.search === 2 && !this.props.choicesMade.building)}
                onChange={!this.props.choicesMade.buildingUN ? () => { this.props.handlerInAds("buildingUN", !this.props.choicesMade.buildingUN); this.disablePriorities(13, 14) } : () => { this.props.handlerInAds("buildingUN", !this.props.choicesMade.buildingUN); this.enablePriorities(13, 14) }}
            />
        </div>)
    }

    renderAppartmentGroup() {
        return (
            <div className="group">
                <Checkbox
                    label={translate("SearchTypeChoice", "checkbox7", this.context)}
                    disabled={this.props.choicesMade.search === 2 && !this.props.choicesMade.building}
                    checked={this.props.choicesMade.apartment}
                    onChange={this.props.choicesMade.apartment ? () => { this.props.handlerInAds("apartment", !this.props.choicesMade.apartment); this.props.handlerInAds("allowNewApartments", false); this.disablePriorities(5, 6, 9, 10) } : () => { this.props.handlerInAds("apartment", !this.props.choicesMade.apartment); this.enablePriorities(5, 6, 9, 10) }}
                />
                <Checkbox
                    className="tabbed"
                    label={translate("SearchTypeChoice", "checkbox8", this.context)}
                    checked={this.props.choicesMade.allowNewApartments}
                    disabled={!this.props.choicesMade.apartment}
                    onChange={this.props.handlerInAds.bind(this, "allowNewApartments", !this.props.choicesMade.allowNewApartments)}
                />
            </div>
        )
    }

    componentWillUnmount() {
        //set the last page before result to this component in case mode was 3 and it skips MapTypeChoice.js
        this.props.handler("pageBeforeResult", "/S2")
    }

    render() {
        //list of years used in the dropdown menu
        const years = [
            {
                key: 'no',
                text: translate("SearchTypeChoice", "nosearch", this.context),
                value: 'no',
            }
        ]

        //for loop adds years 1993 to 2014 to the available "years" list
        for (let i = 1993; i <= 2014; i++) {
            var year = {
                key: String(i),
                text: String(i),
                value: String(i)
            }
            years.push(year)
        }

        //New feature dropdown selection list
        const kuvaKaardilOptions = [
            {
                key: "marker",
                text: translate("SearchTypeChoice", "marker", this.context),
                value: "marker"
            },
            {
                key: "geometry",
                text: translate("SearchTypeChoice", "geometry", this.context),
                value: "geometry"
            },
            {
                key: "marker_and_geometry",
                text: translate("SearchTypeChoice", "marker_and_geometry", this.context),
                value: "marker_and_geometry"
            }
        ]

        return (
            <div>
                <div className="heading">
                    <Divider horizontal>{translate("SearchTypeChoice", "title", this.context)}</Divider>
                </div>

                <Grid centered columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column className="leftSideSTC">
                            <h4>{translate("SearchTypeChoice", "objecttype", this.context)}</h4>
                            <p>{translate("SearchTypeChoice", "objecttypedescript", this.context)}</p>
                            <Grid.Row>
                                <Form>
                                    <Form.Field>
                                        <Radio
                                            label={translate("SearchTypeChoice", "radio1", this.context)}
                                            name='radioGroup'
                                            checked={this.props.choicesMade.search === 1}
                                            onChange={() => { this.props.handlerInAds("search", 1); this.resetChoices(); this.resetPriorities() }}
                                            disabled={this.props.choicesMade.mode === 3}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Radio
                                            label={translate("SearchTypeChoice", "radio2", this.context)}
                                            name='radioGroup'
                                            checked={this.props.choicesMade.search === 2}
                                            onChange={() => { this.props.handlerInAds("search", 2); this.emptyPriorities() }}
                                            disabled={this.props.choicesMade.mode === 3}
                                        />
                                    </Form.Field>
                                </Form>
                                <div className="checkboxGroup">
                                    {this.renderSearchLayerGroup()}
                                    {this.renderPOIandKNRGroup()}
                                    {this.renderAppartmentGroup()}
                                </div>

                            </Grid.Row>
                        </Grid.Column>

                        <Grid.Column className="rightSideSTC">
                            <div className='checkboxGroup'>
                                {this.renderSmallOptionsGroup(kuvaKaardilOptions)}
                            </div>
                            <p>{translate("SearchTypeChoice", "defaultyear", this.context)}</p>
                            <Dropdown
                                defaultValue={this.props.choicesMade.ihist}
                                fluid
                                selection
                                options={years}
                                onChange={(e) => { e.target.textContent === "ei otsita" || e.target.textContent === "Do not allow" ? this.props.handlerInAds("ihist", "0") : this.props.handlerInAds("ihist", e.target.textContent) }}
                            />

                            <h4>{translate("SearchTypeChoice", "priorityrules", this.context)}</h4>
                            <p>{translate("SearchTypeChoice", "priorityrulesdescript", this.context)}</p>
                            <List ordered>
                                {this.props.display.displayedPriorities}
                            </List>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Grid centered columns={2}>
                    <Grid.Row className="buttonRow button">
                        <Grid.Column>
                            <NavButton whereto={"/S1"} icon="arrow left" text="back" />
                        </Grid.Column>
                        <Grid.Column>
                            {this.atleastOneSelectedANDPoiKnrNotEmpty() ?
                                this.checkIfMode3()
                                :
                                <Button className="dummy" animated="fade">
                                    <Button.Content visible>{translate("Buttons", "next", this.context)}</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="attention" />
                                    </Button.Content>
                                </Button>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default SearchTypeChoice