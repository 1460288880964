import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import { Button } from 'semantic-ui-react';

import LanguageContext from './LanguageContext';
import { translate } from '../utils';

class NavButton extends Component {
    //using this.context will return the currently selected language code (eg. "et" or "en")
    static contextType = LanguageContext;

    render() {
        return (
            <Button as={Link} to={this.props.whereto} animated={false}>
                <Button.Content visible>{translate("Buttons", this.props.text, this.context)}</Button.Content>
            </Button>
        );
    }
}

export default NavButton