import React, { Component } from 'react';
import logo from '../style/img/maa-amet-logo.svg';
import { Grid, Dropdown } from 'semantic-ui-react'

import LanguageContext from './LanguageContext';
import { translate } from '../utils';

class Header extends Component {

  //Change language function, captures the event and retrieves the change
  //Updates main state through handler() function
  changeLang(e) {
    switch (e.target.textContent) {
      case 'EST':
        this.props.handler("lang", "et")
        break;
      case 'ENG':
        this.props.handler("lang", "en")
        break;
      default:
        console.log("Error while changing languages")
    }
  }

  //using this.context will return the currently selected language code (eg. "et" or "en")
  static contextType = LanguageContext;

  render() {
    const languages = [
      {
        key: "et",
        text: "EST",
        value: "et"
      },
      {
        key: "en",
        text: "ENG",
        value: "en"
      }
    ]

    return (
      <div className="banner">
        <Grid columns='equal'>
          <Grid.Row columns={3}>
            <Grid.Column computer={2} mobile={4}>
              <a href="https://maaamet.ee/" target="_blank" rel="noreferrer">
                <img src={logo} alt="logo" />
              </a>
            </Grid.Column>

            <Grid.Column textAlign='center'>
              <a id="appName" href={window.REACT_APP_BASENAME}>{translate("Header", "title", this.context)}</a>
              <a id="appNameMobile" href={window.REACT_APP_BASENAME}>{translate("Header", "titleshort", this.context)}</a>
            </Grid.Column>

            <Grid.Column computer={2} mobile={4} textAlign='right'>
              <Dropdown className="dropdown-list languageSelection"
                fluid
                selection
                onChange={this.changeLang.bind(this)}
                options={languages}
                defaultValue={this.props.lang}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default Header