import * as translations from './translation/translations.json';

export const translate = (component, str, lang) => {

    let result = str || '';

    //translations["default"] is the correct tree node to access
    let translation = translations["default"]
    
    if (
        translation &&
        translation[component] &&
        translation[component][str] &&
        translation[component][str][lang]
    ) {
        result = translation[component][str][lang];
    } else {
        console.log('Missing translation:', [component, str, lang]);

        // If translation missing, try using default lang
        lang = "ee";
        if (
            translation &&
            translation[component] &&
            translation[component][str] &&
            translation[component][str][lang]
        )
            result = translation[component][str][lang];
    }

    return result;
};