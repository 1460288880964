import React, { Component } from 'react'
import { Grid, Input, Divider, Checkbox, Dropdown, Button, List, Label, Icon } from 'semantic-ui-react';

import NavButton from './NavButton';

import LanguageContext from './LanguageContext';
import { translate } from '../utils';
export class LocationDisplayAdvanced extends Component {
    //using this.context will return the currently selected language code (eg. "et" or "en")
    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = { displayedWMSFilters: this.loadWMSFilters(this.props.choicesMade.wms), omavalitsus: null, inputField: "" }
    }

    componentDidMount() {
        this.checkWidthHeightValues()
    }

    //check if at least one base map is selected
    atleastOneSelected() {
        for (let el of this.props.choicesMade.baseLayers) {
            if (el[1]) {
                return true
            }
        }
        return false
    }

    //Function that loads the wms filters as displayable elements
    loadWMSFilters(wmsList) {
        let displayedWMSFilters = []

        if (wmsList.length === 0) {
            return []
        }

        let i = 0
        for (let el of wmsList) {
            displayedWMSFilters.push(
                <List.Item key={i} > {el}
                    <Label keyprop={i} onClick={(_e, data) => this.handleFilterListRemove(data.keyprop)}>
                        <Icon name="delete" />
                    </Label>
                </List.Item>
            )

            i += 1
        }
        return displayedWMSFilters
    }

    returnFirstLayerWithTrueValue(list) {
        for (let layer of list) {
            if (layer[1]) {
                return layer[0]
            }
        }
    }

    //Helper function for this component's checkbox functionality
    enableDisableMapBackground(position) {
        var result = this.props.choicesMade.baseLayers

        result[position][1] = !result[position][1]

        let currentDefault = this.props.choicesMade.defaultBackground

        //if the checkboxes are all unchecked and the user finally checks one, then set it as defaultBackground as well
        if (this.props.choicesMade.defaultBackground === undefined) {
            this.props.handlerInAds("defaultBackground", this.returnFirstLayerWithTrueValue(result))
        }

        for (let layer of result) {
            if (layer[0] === currentDefault) {
                //if the currentDefault is not disabled, find a new layer to set as default
                if (!layer[1]) {
                    this.props.handlerInAds("defaultBackground", this.returnFirstLayerWithTrueValue(result))
                }
            }
        }

        //update the render
        this.forceUpdate()
    }

    handleDropdownSelectChange = (_e, { value }) => this.props.handlerInAds("defaultBackground", value)

    handleInputChange = (_e, { value }) => this.setState({ "inputField": value })
    handleWidthChange = (_e, { value }) => { this.props.handlerInAds("width", value); this.checkWidthHeightValues() }
    handleHeightChange = (_e, { value }) => { this.props.handlerInAds("height", value); this.checkWidthHeightValues() }

    handleFilterListRemove(id) {
        //also filter out the filter from the "wms" state list
        for (let el of this.state.displayedWMSFilters) {
            if (Number(el.key) === id) {
                this.state.displayedWMSFilters.splice(this.state.displayedWMSFilters.indexOf(el), 1)
                //el.props.children[0] or el.props.children[1] returns the string value of the el and then it is used to get the index of the string element in "wms" state list
                //the matched element is then removed from the wms state list
                //if the component has not been rerendered, then the string value of the el is on the 0th index
                //if the component has been rerendered, then the string value of the le is on the 1st index
                if (el.props.children[0] === " ") {
                    this.props.choicesMade.wms.splice(this.props.choicesMade.wms.indexOf(el.props.children[1]), 1)
                } else {
                    this.props.choicesMade.wms.splice(this.props.choicesMade.wms.indexOf(el.props.children[0]), 1)
                }

                break;
            }
        }

        //update the displayedWMSFilters list
        this.forceUpdate()

    }

    //handles the add next/lisa järgmine button press
    handleButtonPress() {
        //only add the filter if the inputField is not empty
        if (this.state.inputField !== "") {
            //Add the new filter
            this.props.choicesMade.wms.push(this.state.inputField)
            //Add the filter to the displayed filters list
            let currentList = this.state.displayedWMSFilters

            currentList.push(
                <List.Item key={this.props.choicesMade.wms.length} >{this.state.inputField}
                    <Label keyprop={this.props.choicesMade.wms.length} onClick={(_e, data) => this.handleFilterListRemove(data.keyprop)}>
                        <Icon name="delete" />
                    </Label>
                </List.Item>
            )
            this.setState({ "displayedWMSFilters": currentList })

            //clean the input field
            this.setState({ "inputField": "" })
        }
    }

    //function to change container when updating the input field
    changeDivID(e) {
        this.props.handlerInAds("container", e.target.value)
    }

    checkWidthHeightValues() {
        if (this.props.choicesMade.width < 100 || this.props.choicesMade.width > 1000
            || this.props.choicesMade.height < 100 || this.props.choicesMade.height > 1000) {
            document.querySelector(".warning").style.opacity = "1";
        } else {
            document.querySelector(".warning").style.opacity = "0";
        }
    }

    checkWidthHeightValuesBoolean() {
        return !(this.props.choicesMade.width < 100 || this.props.choicesMade.width > 1000
            || this.props.choicesMade.height < 100 || this.props.choicesMade.height > 1000)
    }

    //dynamically render the checkboxes based on the fetched baseLayers from Gazetteer
    renderBaseLayerCheckboxes() {
        if (this.props.choicesMade.baseLayers) {
            var layers = this.props.choicesMade.baseLayers
            var checkboxes = []
            for (let i = 0; i < layers.length; i++) {
                checkboxes.push(<Checkbox
                    key={i}
                    label={translate("MapTypeChoice", layers[i][0], this.context)}
                    checked={layers[i][1]}
                    onChange={() => this.enableDisableMapBackground(i)}
                />)
            }
            return checkboxes
        }
        else return []
    }

    render() {
        //Only render the component if data is loaded
        if (!this.props.display.loadedBaseLayers) {
            return <div />
        }

        //list for the dropdown
        const backgrounds = []

        //add options to the list, which are turned on by the checkboxes
        for (let el of this.props.choicesMade.baseLayers) {
            if (el[1]) {
                var add = {
                    key: el[0],
                    text: translate("MapTypeChoice", el[0], this.context),
                    value: el[0]
                }
                backgrounds.push(add)
            }
        }

        return (
            <div>
                <div className="heading">
                    <Divider horizontal>{translate("LocationDisplayAdvanced", "title", this.context)}</Divider>
                </div>
                <Grid centered columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <h4>{translate("MapTypeChoice", "mapbackgroundtitle", this.context)}</h4>
                            <p>{translate("MapTypeChoice", "mapbackgroundtext", this.context)}</p>

                            <div className="checkboxGroup">
                                <div className={this.atleastOneSelected() ? "group" : "group_attention"}>
                                    {this.renderBaseLayerCheckboxes()}
                                </div>
                            </div>

                            <p>{translate("MapTypeChoice", "defaultbackground", this.context)}</p>
                            <Dropdown
                                value={this.props.choicesMade.defaultBackground}
                                fluid
                                selection
                                options={backgrounds}
                                onChange={this.handleDropdownSelectChange}
                            />
                            <Grid.Row>
                                <h4 style={{ "marginTop": "20px" }}>{translate("ComponentTypeChoice", "divID", this.context)}</h4>
                                <Input icon='qrcode' iconPosition='left' defaultValue={this.props.choicesMade.container} onChange={this.changeDivID.bind(this)} />
                            </Grid.Row>
                            <Grid.Row>
                                <div className="checkboxGroup">
                                    <Checkbox
                                        label={translate("ComponentTypeChoice", "checkbox1", this.context)}
                                        checked={this.props.choicesMade.langChange}
                                        onChange={this.props.handlerInAds.bind(this, "langChange", !this.props.choicesMade.langChange)}
                                    />
                                    <Checkbox
                                        label={translate("ComponentTypeChoice", "checkbox2", this.context)}
                                        disabled={this.props.choicesMade.mode === 3}
                                        checked={this.props.choicesMade.showscale}
                                        onChange={this.props.handlerInAds.bind(this, "showscale", !this.props.choicesMade.showscale)}
                                    />
                                    <Checkbox
                                        label={translate("ComponentTypeChoice", "checkbox3", this.context)}
                                        disabled={this.props.choicesMade.mode === 3}
                                        checked={this.props.choicesMade.measure}
                                        onChange={this.props.handlerInAds.bind(this, "measure", !this.props.choicesMade.measure)}
                                    />
                                    <Checkbox
                                        label={translate("ComponentTypeChoice", "checkbox4", this.context)}
                                        disabled={this.props.choicesMade.mode === 2 || this.props.choicesMade.mode === 3}
                                        checked={this.props.choicesMade.fullscreen}
                                        onChange={this.props.handlerInAds.bind(this, "fullscreen", !this.props.choicesMade.fullscreen)}
                                    />
                                </div>
                            </Grid.Row>
                        </Grid.Column>

                        <Grid.Column>
                            <h4>{translate("MapTypeChoice", "maplayerchoicetitle", this.context)}</h4>
                            <p>{translate("MapTypeChoice", "maplayerchoicetext", this.context)}</p>

                            <div className="checkboxGroup">
                                <div className="group">
                                    <Checkbox
                                        label={translate("MapTypeChoice", "addresses", this.context)}
                                        checked={this.props.choicesMade.mapLayersAddress}
                                        onChange={() => this.props.handlerInAds("mapLayersAddress", !this.props.choicesMade.mapLayersAddress)}
                                    />
                                    <Checkbox
                                        label={translate("MapTypeChoice", "cadastral", this.context)}
                                        checked={this.props.choicesMade.mapLayersCadastral}
                                        onChange={() => this.props.handlerInAds("mapLayersCadastral", !this.props.choicesMade.mapLayersCadastral)}
                                    />
                                </div>
                            </div>

                            <h4>{translate("MapTypeChoice", "wmstitle", this.context)}</h4>
                            <p>{translate("MapTypeChoice", "wmstext", this.context)}</p>

                            <div className="inputField">
                                <Input onChange={this.handleInputChange} />
                                <Button
                                    onClick={() => this.handleButtonPress()}
                                >{translate("MapTypeChoice", "addbtn", this.context)}</Button>
                            </div>

                            <p>{this.props.choicesMade.wms.length !== 0 ? translate("MapTypeChoice", "addedlayers", this.context) : null}</p>

                            <div className="filterList">
                                <List bulleted>
                                    {this.state.displayedWMSFilters}
                                </List>
                            </div>

                            <h4 id="dimensionsHeader">{translate("LocationDisplayAdvanced", "dimensions", this.context)}</h4>
                            <p className="warning">{translate("LocationDisplayAdvanced", "dimensionswarning", this.context)}</p>
                            <div className="mapSizing">
                                <p>{translate("LocationDisplayAdvanced", "width", this.context)}</p>
                                <Input type="number" min="100" max="1000" value={this.props.choicesMade.width} onChange={this.handleWidthChange} />
                                <p>{translate("LocationDisplayAdvanced", "height", this.context)}</p>
                                <Input type="number" min="100" max="1000" value={this.props.choicesMade.height} onChange={this.handleHeightChange} />
                            </div>


                        </Grid.Column>
                    </Grid.Row>
                </Grid>


                <Grid centered columns={2}>
                    <Grid.Row className="buttonRow button">
                        <Grid.Column>
                            <NavButton whereto={"/S3e"} icon="arrow left" text="back" />
                        </Grid.Column>
                        <Grid.Column>
                            {this.checkWidthHeightValuesBoolean() && this.atleastOneSelected() ?
                                <NavButton whereto={"/S4e2"} icon="arrow right" text="preview" />
                                :
                                <Button className="dummy" animated="fade">
                                    <Button.Content visible>{translate("Buttons", "preview", this.context)}</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="attention" />
                                    </Button.Content>
                                </Button>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

export default LocationDisplayAdvanced
