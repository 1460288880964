import './App.css';
import 'semantic-ui-css/semantic.min.css'
import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react'

import Header from './components/Header'
import Footer from './components/Footer'
import Intro from './components/Intro'
import S1 from './components/ComponentTypeChoice'
import S2 from './components/SearchTypeChoice'
import S3 from './components/MapTypeChoice'
import S3e from './components/LocationDisplay'
import S4 from './components/CodeGeneratorResult'
import S4e from './components/LocationDisplayAdvanced'
import S4e2 from './components/LocationDisplayPreview'

import ELfondLogo from './style/img/el_regionaalarengu_fond_vertikaalne.png';
import fetchJsonp from 'fetch-jsonp'

import { MemoryRouter as Router, Route, Switch } from 'react-router-dom';
import LanguageContext from './components/LanguageContext';

class App extends Component {

  //Default values:
  state = {
    lang: "et",
    loadedBaseLayers: false,
    loadedInAdsScript: false,
    //In-Ads object
    inadsObj: {
      //ComponentTypeChoice.js values
      mode: 1,
      container: "InAadressDiv", //divElementID
      langChange: false, //NEW FEATURE: display language choice or not
      showscale: false, //displayScale
      measure: false, //addMeasureTool
      fullscreen: false,
      keyboard: true, //NEW FEATURE 2.5
      focus: false, //NEW FEATURE 2.6
      inputPosition: false, //NEW FEATURE 2.13 false-"bottom", true-"top"
      //SearchTypeChoice.js values
      search: 1, //1-address, 2-object
      ehak: false,
      territorial: false,
      cadastral: false,
      traffic: false,
      maPiirkond: false, //NEW FEATURE 2.4 Janno öeldud
      building: false,
      buildingUN: false,
      apartment: false,
      allowNewApartments: false,
      filters: false,
      ihist: "1993",
      hideResult: true, //NEW FEATURE 2.9
      valueField: true, //NEW FEATURE 2.9
      help: true, //NEW FEATURE Janno öeldud
      displayGray: true, //NEW FEATURE 2.17
      poi: false, //NEW FEATURE
      knr: false, //NEW FEATURE
      type: ["10", "11", "12", "13", "14", "15", "16"], //NEW FEATURE poi and knr types
      displayGeom: "marker_and_geometry", //NEW FEATURE
      ky: false, //NEW FEATURE
      iTappAsendus: false, //NEW FEATURE
      tech: true, //NEW FEATURE
      selectBuilding: false, //NEW FEATURE
      //MapTypeChoice.js values
      baseLayers: null,
      defaultBackground: "ALUSKAART",
      mapLayersAddress: false,
      mapLayersCadastral: false,
      wms: [],
      ehakFilter: "-1",
      //LocationDisplay.js values
      labelMode: 1,
      width: "600",
      height: "450"
    },

    // list of priorities, order is important, every priority is stored in a list where the second element is either "true"-on or "false"-off
    // the longer names are shown in the translations.json file
    priority: [           //indeces
      ["counties", true],//0
      ["localgov", true],//1
      ["settlementsandcitydistricts", 1],//2
      ["territorialaddressunits", true],//3
      ["traffic", true],//4
      ["residentialEHR", true],//5
      ["residentialnonEHR", true],//6
      ["UNwithEHR", true],//7
      ["UNwithoutEHR", true],//8
      ["nonresidentialEHR", true],//9
      ["nonresidentialnonEHR", true],//10
      ["cadastral", true],//11
      ["cadastralnobuildings", true],//12
      ["EHRwithoutUN", true],//13
      ["nonEHRwithoutUN", true]//14
    ],
    ehakCounty: "-1",
    ehakOV: "-1",
    //LocationDisplay
    addresses: [],
    idCounter: 0,
    mapPointsList: null,
  }

  componentDidMount() {
    this.loadInAdsScript()

    if (this.state.loadedBaseLayers === false) {
      this.fetchBaseLayers()
    }
  }

  //makes it possible for child components to update the state, passed as a prop
  handler = (key, val) => {
    this.setState({ [key]: val })
  }

  //makes it possible for child components to update the inadsObj state variable comfortably
  handlerInAds = (key, val) => {
    let obj = this.state.inadsObj
    obj[key] = val
    this.setState({ inadsObj: obj })
  }

  loadInAdsScript() {
    let path
    
    if (window.REACT_APP_INADS_PATH_BASE_URL === "") {
      path = window.location.origin + window.REACT_APP_INADS_PATH;
    }
    else {
      path = window.REACT_APP_INADS_PATH_BASE_URL + window.REACT_APP_INADS_PATH
    }
    //load in inAds Script
    const script = document.createElement("script");
    script.src = path;
    document.body.appendChild(script)
    script.addEventListener('load', () => {
      this.setState({ "loadedInAdsScript": true })
    })

  }

  fetchBaseLayers() {
    var baseLayers = []

    //fetch the baseLayers
    let path;
    if (window.REACT_APP_INADS_PATH_BASE_URL === "") {
      path = window.REACT_APP_LAYERS_FETCH_URL
    } else {
      path = window.REACT_APP_INADS_PATH_BASE_URL + window.REACT_APP_LAYERS_FETCH_URL
    }

    fetchJsonp(path)
      .then(
        (response) => {
          response.json().then(data => ({
            data: data,
          })).then(result => {
            for (var layer of Object.keys(result.data.baseLayers)) {
              baseLayers.push([layer, false])
            }
            //turn on "ALUSKAART" by default
            baseLayers[0][1] = true

            //update the inadsObj in state
            var inAdsObject = this.state.inadsObj
            inAdsObject["baseLayers"] = baseLayers

            //replace the old inadsObj with the new modified one
            this.setState({ "inadsObj": inAdsObject })

            //let the render know, that the baseLayers are now loaded
            this.setState({ "loadedBaseLayers": true })
          }).catch((error) => {
            this.setState({ "errorWhenFetching": true, "errorMsg": error })
            console.log(error)
          })
        },
      )
  }

  renderErrorMsg() {
    return (
      <div>
        <h2>Could not retrieve information necessary for the App to work.</h2>
        <h2>Try again later</h2>
        <h2>If the problem persists, contact support</h2>
        <h3>{this.errorMsg}</h3>
      </div>
    )
  }

  render() {

    //Only render the component if baseLayers data is loaded
    if (!this.state.loadedBaseLayers || !this.state.loadedInAdsScript) {
      return (
        <div className="App">
          <LanguageContext.Provider value={this.state.lang}>
            <Header className="App-Header" lang={this.state.lang} handler={this.handler} />
            <Router basename={window.REACT_APP_BASENAME}>
              <img id="fundLogo1" src={ELfondLogo} alt="Fund logo" />
              <div className="view">
                {this.state.errorWhenFetching ? this.renderErrorMsg() : <Loader active />}
              </div>
            </Router>
            <Footer className="App-footer" showState={this.state} />
          </LanguageContext.Provider>
        </div>
      );
    }

    return (
      <div className="App">
        <LanguageContext.Provider value={this.state.lang}>
          <Header className="App-Header" lang={this.state.lang} handler={this.handler} />
          <Router basename={window.REACT_APP_BASENAME}>
            <img id="fundLogo1" src={ELfondLogo} alt="Fund logo" />
            <div className="view">
              <Switch>
                <Route exact path="/">
                  <Intro choicesMade={this.state.inadsObj} handlerInAds={this.handlerInAds} />
                </Route>

                <Route exact path="/S1">
                  <S1 choicesMade={this.state.inadsObj} handler={this.handler} handlerInAds={this.handlerInAds} />
                </Route>

                <Route exact path="/S2">
                  <S2 choicesMade={this.state.inadsObj} display={this.state} handler={this.handler} handlerInAds={this.handlerInAds} />
                </Route>

                <Route exact path="/S3">
                  <S3 choicesMade={this.state.inadsObj} display={this.state} handler={this.handler} handlerInAds={this.handlerInAds} />
                </Route>

                <Route exact path="/S3e">
                  <S3e choicesMade={this.state.inadsObj} display={this.state} handler={this.handler} handlerInAds={this.handlerInAds} />
                </Route>

                <Route exact path="/S4">
                  <S4 choicesMade={this.state.inadsObj} display={this.state} handler={this.handler} handlerInAds={this.handlerInAds} />
                </Route>

                <Route exact path="/S4e">
                  <S4e choicesMade={this.state.inadsObj} display={this.state} handler={this.handler} handlerInAds={this.handlerInAds} />
                </Route>

                <Route exact path="/S4e2">
                  <S4e2 choicesMade={this.state.inadsObj} display={this.state} handler={this.handler} handlerInAds={this.handlerInAds} />
                </Route>
              </Switch>
            </div>
          </Router>
          <Footer className="App-footer" showState={this.state} />
        </LanguageContext.Provider>
      </div>
    );
  }
}


export default App;
