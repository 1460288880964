import { Component } from 'react';
import { Grid } from 'semantic-ui-react'

import { version } from "../../package.json"

import LanguageContext from './LanguageContext';
import { translate } from '../utils';

class Footer extends Component {
    //using this.context will return the currently selected language code (eg. "et" or "en")
    static contextType = LanguageContext;

    render() {
        return (
            <footer className="appFooter">
                <Grid centered stackable columns={1}>
                    <Grid.Column>
                        <p id="desktopTitle">{translate("Footer", "title", this.context)}</p>
                        <div id="mobileTitle">
                            <p>{translate("Footer", "mobileTitle", this.context)}</p>
                            <p>{translate("Footer", "mobileEmail", this.context)}</p>
                        </div>
                    </Grid.Column>
                    <Grid.Column computer={2} mobile={4}>
                        <p>{version}</p>
                    </Grid.Column>
                </Grid>
            </footer>
        )
    }
}


export default Footer