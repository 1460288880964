import React, { Component } from 'react';
import { Grid, Form, Radio, Input, Divider, Checkbox, Button, Icon } from 'semantic-ui-react';

import NavButton from './NavButton';

import LanguageContext from './LanguageContext';
import { translate } from '../utils';

class ComponentTypeChoice extends Component {

    componentDidMount() {
        document.body.appendChild(this.createScript());
    }

    createScript() {
        const script = document.createElement("script");
        script.id = "inAdsScript"
        script.text = 'var inAadress = new InAadress({"container":"InAadressDiv","mode":' + this.props.choicesMade.mode + ',"inputPosition":"' + this.returnInputPosition(this.props.choicesMade.inputPosition) + '","appartment":0,"ihist":"1993","defaultBaseLayer":"ALUSKAART","baseLayers":["ALUSKAART"],"WMS":[],"lang":"' + this.context + '","hideLogo":true});'
        this.setState({ script: script })
        return script
    }

    returnInputPosition(boolean) {
        return boolean ? 'top' : 'bottom'
    }

    //function to change container when updating the input field
    changeDivID(e) {
        this.props.handlerInAds("container", e.target.value)
    }

    //rotates the arrow icon when clicking on it
    rotateIcon() {
        var icon = document.getElementById("arrow")
        icon.classList.toggle("reverse")
    }

    //checks and adds "reverse" class to the icon, if inputPosition is false
    checkIconArrow() {
        if (this.props.choicesMade.inputPosition) {
            return "reverse"
        }
        return ""
    }

    changeMode() { //Helper function to change the mode of the InAds component
        document.getElementById("InAadressDiv").innerHTML = ""
        document.getElementById("InAadressDiv").style.height = "510px"; //have to reset the height style to avoid errors going from mode 3 -> 1

        this.cleanUpScript() //clean up before adding a new script tag
        document.body.appendChild(this.createScript());
    }

    //cleans up the DOM
    cleanUpScript() {
        document.body.removeChild(this.state.script)
    }

    componentWillUnmount() {
        this.cleanUpScript()
    }

    //using this.context will return the currently selected language code (eg. "et" or "en")
    static contextType = LanguageContext;

    render() {
        return (
            <div>
                <div className="heading">
                    <Divider horizontal>{translate("ComponentTypeChoice", "title", this.context)}</Divider>
                </div>
                <Grid centered columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column className="leftSideCTC">
                            <div className="exampleContainer">
                                <div id="InAadressDiv" />
                            </div>
                        </Grid.Column>

                        <Grid.Column className="rightSideCTC">
                            <Grid.Row>
                                <h4>{translate("ComponentTypeChoice", "widgetstyle", this.context)}</h4>

                                {/* Radio element tekitab findDOMNode warningut */}
                                <Form>
                                    <Form.Field>
                                        <Radio
                                            label={translate("ComponentTypeChoice", "radio1", this.context)}
                                            name='radioGroup'
                                            value='1'
                                            checked={this.props.choicesMade.mode === 1}
                                            onChange={
                                                () => { this.props.handlerInAds("mode", 1); this.changeMode() }
                                            }
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Radio
                                            label={translate("ComponentTypeChoice", "radio2", this.context)}
                                            name='radioGroup'
                                            value='2'
                                            checked={this.props.choicesMade.mode === 2}
                                            onChange={
                                                () => { this.props.handlerInAds("mode", 2); this.props.handlerInAds("fullscreen", false); this.changeMode() }
                                            }
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Radio
                                            label={translate("ComponentTypeChoice", "radio3", this.context)}
                                            name='radioGroup'
                                            value='3'
                                            checked={this.props.choicesMade.mode === 3}
                                            onChange={
                                                () => { this.props.handlerInAds("mode", 3); this.props.handlerInAds("showscale", false); this.props.handlerInAds("measure", false); this.props.handlerInAds("fullscreen", false); this.changeMode() }
                                            }
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Radio
                                            label={translate("ComponentTypeChoice", "radio4", this.context)}
                                            name='radioGroup'
                                            value='4'
                                            checked={this.props.choicesMade.mode === 4}
                                            onChange={
                                                () => { this.props.handlerInAds("mode", 4); this.changeMode() }
                                            }
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Row>
                            <Grid.Row>
                                <h4 style={{ "marginTop": "20px" }}>{translate("ComponentTypeChoice", "divID", this.context)}</h4>
                                <Input icon='qrcode' iconPosition='left' defaultValue={this.props.choicesMade.container} onChange={this.changeDivID.bind(this)} />
                            </Grid.Row>
                            <Grid.Row>
                                <div className="checkboxGroup">
                                    <Checkbox
                                        label={translate("ComponentTypeChoice", "checkbox1", this.context)}
                                        disabled={this.props.choicesMade.mode === 3}
                                        checked={this.props.choicesMade.langChange}
                                        onChange={this.props.handlerInAds.bind(this, "langChange", !this.props.choicesMade.langChange)}
                                    />
                                    <Checkbox
                                        label={translate("ComponentTypeChoice", "checkbox2", this.context)}
                                        disabled={this.props.choicesMade.mode === 3}
                                        checked={this.props.choicesMade.showscale}
                                        onChange={this.props.handlerInAds.bind(this, "showscale", !this.props.choicesMade.showscale)}
                                    />
                                    <Checkbox
                                        label={translate("ComponentTypeChoice", "checkbox3", this.context)}
                                        disabled={this.props.choicesMade.mode === 3}
                                        checked={this.props.choicesMade.measure}
                                        onChange={this.props.handlerInAds.bind(this, "measure", !this.props.choicesMade.measure)}
                                    />
                                    <Checkbox
                                        label={translate("ComponentTypeChoice", "checkbox4", this.context)}
                                        disabled={this.props.choicesMade.mode === 2 || this.props.choicesMade.mode === 3}
                                        checked={this.props.choicesMade.fullscreen}
                                        onChange={this.props.handlerInAds.bind(this, "fullscreen", !this.props.choicesMade.fullscreen)}
                                    />
                                </div>

                                <h4>{translate("ComponentTypeChoice", "searchboxcustomizationtitle", this.context)}</h4>
                                <div className="checkboxGroup">
                                    <Checkbox
                                        label={translate("ComponentTypeChoice", "checkbox5", this.context)}
                                        checked={this.props.choicesMade.keyboard}
                                        disabled={this.props.choicesMade.mode === 4}
                                        onChange={this.props.handlerInAds.bind(this, "keyboard", !this.props.choicesMade.keyboard)}
                                    />
                                    <Checkbox
                                        label={translate("ComponentTypeChoice", "checkbox6", this.context)}
                                        checked={this.props.choicesMade.focus}
                                        disabled={this.props.choicesMade.mode === 4}
                                        onChange={this.props.handlerInAds.bind(this, "focus", !this.props.choicesMade.focus)}
                                    />
                                    <Checkbox
                                        label={translate("ComponentTypeChoice", "checkbox7", this.context)}
                                        checked={this.props.choicesMade.hideResult}
                                        disabled={this.props.choicesMade.mode === 4}
                                        onChange={this.props.handlerInAds.bind(this, "hideResult", !this.props.choicesMade.hideResult)}
                                    />
                                    <Checkbox
                                        label={translate("ComponentTypeChoice", "checkbox8", this.context)}
                                        checked={this.props.choicesMade.valueField}
                                        disabled={this.props.choicesMade.mode === 4}
                                        onChange={this.props.handlerInAds.bind(this, "valueField", !this.props.choicesMade.valueField)}
                                    />
                                    <div className="checkboxStyledElement">
                                        {translate("ComponentTypeChoice", "searchboxlocationtitle", this.context)}
                                        <div id="searchBoxLocationButton">
                                            <Button
                                                disabled={this.props.choicesMade.mode === 4 || this.props.choicesMade.mode === 3}
                                                onClick={() => { this.props.handlerInAds("inputPosition", !this.props.choicesMade.inputPosition); this.rotateIcon(); this.changeMode() }}>
                                                <Icon id="arrow" className={this.checkIconArrow()} name="arrow down" />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid centered columns={2}>
                    <Grid.Row className="buttonRow button">
                        <Grid.Column>
                            <NavButton whereto={"/"} icon="arrow left" text="back" />
                        </Grid.Column>
                        <Grid.Column>
                            <NavButton whereto={this.props.choicesMade.mode === 4 ? "/S3e" : "/S2"} icon="arrow right" text="next" />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default ComponentTypeChoice;