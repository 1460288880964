import React, { Component } from 'react'
import { Grid, Form, Radio, Divider, Button, Icon } from 'semantic-ui-react';

import LanguageContext from './LanguageContext';
import NavButton from './NavButton';
import { translate } from '../utils';

export class LocationDisplay extends Component {
    state = {
        //the arrow functions need to be saved to variable so it could be referenced when removing/adding the same eventListener
        loadMarkers: () => this.loadMarkers(),
        addressSelected: (e) => {
            this.addressSelected(e)
        }
    }

    //using this.context will return the currently selected language code (eg. "et" or "en")
    static contextType = LanguageContext;

    componentDidMount() {
        this.props.handlerInAds("mode", 4)
        //Map on the left
        this.createMapScript()
        //Text field on the right
        this.createTextFieldScript()

        //re-render the input fields (only if there were previously added markers)
        this.loadInputFields()
    }

    //loads the markers on the map
    loadMarkers() {
        if (this.props.display.addresses.length > 0) {
            window.locationDisplay.setMarkers(this.props.display.addresses, false)
        }
    }

    //load previous input fields with delete buttons
    loadInputFields() {
        if (this.props.display.addresses.length > 0) {

            document.querySelector("#inaadressText").style.display = "none";

            for (let address of this.props.display.addresses) {
                //add an input field with a button next to it
                document.querySelector(".mapPointsList").insertAdjacentHTML("beforeend", "<div id='point" + address.ident + "' style='display:flex;'><input id='input" + address.ident + "' value='" + address.title + "' /><button id='delBtn" + address.ident + "'>✕</button></div>");

                //BUTTON FUNCTIONALITY:
                this.addDeleteButtonFunctionality(address.ident)

                //INPUT FUNCTIONALITY:
                this.addInputFieldFunctionality(address.ident)
            }

            document.querySelector(".pointButtons").style.display = "flex";
        }
    }

    createMapScript() {
        const locationDisplayScript = document.createElement("script");
        locationDisplayScript.id = "locationDisplayScript"

        if (this.props.choicesMade.markers === null) {
            locationDisplayScript.text = 'var locationDisplay = new InAadress({"container":"locationDisplay","mode":"4","nocss":false,"appartment":0,"ihist":"1993","defaultBaseLayer":"ALUSKAART","baseLayers":["ALUSKAART"],"WMS":[],"lang":"et","hideLogo":true});'
        } else {
            locationDisplayScript.text = 'var locationDisplay = new InAadress({"container":"locationDisplay","mode":"4","nocss":false,"appartment":0,"ihist":"1993","defaultBaseLayer":"ALUSKAART","baseLayers":["ALUSKAART"],"WMS":[],"lang":"et","hideLogo":true,"markers":' + JSON.stringify(this.props.choicesMade.markers) + '});'
        }

        this.setState({ mapScript: locationDisplayScript }) //save this exact script
        document.body.appendChild(locationDisplayScript);

        document.addEventListener("inaadressLoaded", this.state.loadMarkers)
    }

    createTextFieldScript() {
        const textfieldScript = document.createElement("script");
        textfieldScript.id = "textfieldScript"
        textfieldScript.text = 'var textfield = new InAadress({ "hideLogo": 1,"container": "inaadressText","mode": "3","ihist":"1993","lang":"' + this.context + '"});'
        this.setState({ textfieldScript: textfieldScript }) //save this exact script
        document.body.appendChild(textfieldScript);

        document.addEventListener('addressSelected', this.state.addressSelected);
    }

    //Add an onclick event to the button which removes an address from this.props.display.addresses list based on the "id" value.
    //The addresses list contains objects with "ident" identifiers and when an "ident" is matched with "id", the marker/address is removed from the list
    addDeleteButtonFunctionality(id) {
        document.querySelector("#delBtn" + id).onclick = () => {
            var addresses = this.props.display.addresses

            for (let i = 0; i < addresses.length; i++) {
                if (addresses[i].ident === id) {
                    addresses.splice(i, 1) //remove it from the list
                    //reset the map
                    window.locationDisplay.clear()
                    window.locationDisplay.setMarkers(addresses, false)
                    //remove the input field and button
                    document.querySelector("#point" + id).remove()

                    //if there are no points left to remove, re-display the InAds text field and hide the ".pointButtons" element
                    if (!document.querySelector(".mapPointsList").hasChildNodes()) {
                        document.querySelector("#inaadressText").style.display = "block";
                        document.querySelector("#inaadressText").style.height = "29px";
                        document.querySelector(".pointButtons").style.display = "none";
                    }
                    break;
                }
            }
            this.forceUpdate()
        };
    }

    //changes marker title when user changes something in the input
    addInputFieldFunctionality(id) {
        var addresses = this.props.display.addresses

        document.querySelector("#input" + id).addEventListener("keyup", (e) => {
            for (let i = 0; i <= addresses.length; i++) {
                if (addresses[i].ident === id) {
                    addresses[i].title = e.target.value
                    window.locationDisplay.clear()
                    window.locationDisplay.setMarkers(addresses, false)
                    break;
                }
            }
        });
    }

    addressSelected(e) {
        //getting info out of the event
        var aadress = e.detail instanceof Array ? e.detail[0] : e.detail;
        var x = (aadress.mapx ? aadress.mapx : aadress.x);
        var y = (aadress.mapy ? aadress.mapy : aadress.y);
        var title = aadress.paadress ? aadress.paadress : "defaultTitle";
        var exists = false;

        var addresses = this.props.display.addresses;

        //avoid duplicates
        for (let adr of addresses) {
            if (adr.x === x && adr.y === y) {
                exists = true;
                break;
            }
        }

        window.textfield.hideResult(); //hide the suggestions
        window.textfield.clear(); //clears the InAds textfield

        var counter = this.props.display.idCounter;

        //if the next input was not a duplicate, then execute this code
        if (!exists) {
            addresses.push({
                ident: counter,
                x: x,
                y: y,
                title: title
            });

            //hide the InAds text field
            document.querySelector("#inaadressText").style.display = "none";

            //add an input field with a button next to it
            document.querySelector(".mapPointsList").insertAdjacentHTML("beforeend", "<div id='point" + counter + "' style='display:flex;'><input id='input" + counter + "' value='" + title + "' /><button id='delBtn" + counter + "'>✕</button></div>");

            //BUTTON FUNCTIONALITY:
            this.addDeleteButtonFunctionality(counter)

            //INPUT FUNCTIONALITY:
            this.addInputFieldFunctionality(counter)

            //increase the id counter in state
            this.props.handler("idCounter", this.props.display.idCounter + 1)
        } else {
            //if it was a duplicate, then hide the InAds textfield
            document.querySelector("#inaadressText").style.display = "none";
        }

        //display the buttons, if it hasn't been already done so
        if (window.getComputedStyle(document.querySelector(".pointButtons"), null).display === "none") {
            document.querySelector(".pointButtons").style.display = "flex";
        }

        //set the new locations on the map
        window.locationDisplay.clear()
        window.locationDisplay.setMarkers(addresses, true)
    }

    changeLabelMode(mode) {
        if (mode === 1) {
            window.locationDisplay.setLabelMode("label");
        } else if (mode === 2) {
            window.locationDisplay.setLabelMode("tooltip");
        } else if (mode === 3) {
            window.locationDisplay.setLabelMode("label-tooltip");
        }
        window.locationDisplay.clear()
        window.locationDisplay.setMarkers(this.props.display.addresses, false)
    }

    fitObjects() {
        window.locationDisplay.clear();
        window.locationDisplay.setMarkers(this.props.display.addresses, true);
    }

    deleteObjects() {
        this.props.handler("addresses", [])
        window.locationDisplay.clearMarkers();
        document.querySelector(".mapPointsList").innerHTML = "";
        document.querySelector("#inaadressText").style.display = "block";
        document.querySelector(".pointButtons").style.display = "none";
    }

    atleastOneMarkerAdded() {
        return this.props.display.addresses.length > 0;
    }

    //cleans up the DOM from the added scripts and divs
    cleanUpScript() {
        document.body.removeChild(this.state.mapScript)

        document.body.removeChild(this.state.textfieldScript)
    }

    componentWillUnmount() {
        if (this.props.display.addresses.length > 0) {
            //remember the view position of the map and the addresses
            const bbox = window.locationDisplay.getMap().getView().calculateExtent();
            this.props.handlerInAds("markers", { bbox: [bbox[0], bbox[1], bbox[2], bbox[3]], addresses: this.props.display.addresses })
        } else {
            this.props.handlerInAds("markers", null)
        }


        this.cleanUpScript()

        //remove the old event listeners
        //if this is not done then every time this component gets loaded, new event listeners will be added. This will create bugs
        document.removeEventListener("inaadressLoaded", this.state.loadMarkers)
        document.removeEventListener('addressSelected', this.state.addressSelected);
    }

    render() {
        return (
            <div>
                <div className="heading">
                    <Divider horizontal>{translate("LocationDisplay", "title", this.context)}</Divider>
                </div>
                <Grid centered columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column className="leftSideCTC">
                            <div className="exampleContainer">
                                <div id="locationDisplay" />
                            </div>
                        </Grid.Column>

                        <Grid.Column className="rightSideLD">
                            <Grid.Row>
                                <h4 className={this.atleastOneMarkerAdded() ? null : "attention"}> {translate("LocationDisplay", "enteraddress", this.context)}</h4>
                                <div className="mapPointsList"></div>
                                <div className="inAds">
                                    <div id="inaadressText" />
                                </div>
                                <div className="pointButtons">
                                    <Button onClick={() => { document.querySelector("#inaadressText").style.display = "block" }}>{translate("LocationDisplay", "newobject", this.context)}</Button>
                                    <Button onClick={() => { this.fitObjects() }}>{translate("LocationDisplay", "fitobjects", this.context)}</Button>
                                    <Button onClick={() => { this.deleteObjects() }}>{translate("LocationDisplay", "deleteobjects", this.context)}</Button>
                                </div>
                                <Form>
                                    <Form.Field>
                                        <Radio
                                            label={translate("LocationDisplay", "showlabel", this.context)}
                                            name='radioGroup'
                                            value='1'
                                            checked={this.props.choicesMade.labelMode === 1}
                                            onChange={
                                                () => { this.props.handlerInAds("labelMode", 1); this.changeLabelMode(this.props.choicesMade.labelMode); }
                                            }
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Radio
                                            label={translate("LocationDisplay", "showtooltip", this.context)}
                                            name='radioGroup'
                                            value='2'
                                            checked={this.props.choicesMade.labelMode === 2}
                                            onChange={
                                                () => { this.props.handlerInAds("labelMode", 2); this.changeLabelMode(this.props.choicesMade.labelMode); }
                                            }
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Radio
                                            label={translate("LocationDisplay", "showboth", this.context)}
                                            name='radioGroup'
                                            value='3'
                                            checked={this.props.choicesMade.labelMode === 3}
                                            onChange={
                                                () => { this.props.handlerInAds("labelMode", 3); this.changeLabelMode(this.props.choicesMade.labelMode); }
                                            }
                                        />
                                    </Form.Field>
                                </Form>

                                <p>{translate("LocationDisplay", "explanation", this.context)}</p>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid centered columns={2}>
                    <Grid.Row className="buttonRow button">
                        <Grid.Column>
                            <NavButton whereto={"/"} icon="arrow left" text="back" />
                        </Grid.Column>
                        <Grid.Column>
                            {this.atleastOneMarkerAdded() ?
                                <NavButton whereto={"/S4e"} icon="arrow right" text="continueadvanced" />
                                :
                                <Button className="dummy" animated="fade">
                                    <Button.Content visible>{translate("Buttons", "continueadvanced", this.context)}</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="attention" />
                                    </Button.Content>
                                </Button>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

export default LocationDisplay
