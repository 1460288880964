import React, { Component } from 'react'

import { Grid, Divider, Icon, Popup } from 'semantic-ui-react';

import NavButton from './NavButton';

import LanguageContext from './LanguageContext';
import { translate } from '../utils';

const UserPDFest = "/inaadress/js/pdf/et/in_aadress_manual.pdf"
const UserPDFeng = "/inaadress/js/pdf/en/in_aadress_manual.pdf"
const DevPDFest = "/inaadress/js/pdf/et/in_aadress_developer_manual.pdf"
const DevPDFeng = "/inaadress/js/pdf/en/in_aadress_developer_manual.pdf"

export function generateCode(choices, lang, fullscreenTemporarilyOff = false) {
	var generatedChoices = {}

	//ComponentTypeChoice
	generatedChoices["container"] = choices.container
	generatedChoices["mode"] = choices.mode
	generatedChoices["ihist"] = choices.ihist

	//if mode is 3, these parameters need to be ignored
	if (choices.mode !== 3) {
		//ComponentTypeChoice
		if (choices.showscale) generatedChoices["showScale"] = choices.showscale
		if (choices.measure) generatedChoices["tools"] = ["measure"]
		if (choices.fullscreen && !fullscreenTemporarilyOff) generatedChoices["fullscreenMode"] = choices.fullscreen
		//if it is mode 4, ignore these
		if (choices.mode !== 4) {
			if (choices.focus) generatedChoices["focus"] = choices.focus
			if (!choices.valueField) generatedChoices["valueField"] = choices.valueField //when parameter is missing then the default is true, so only add it when it's false
			if (!choices.hideResult) generatedChoices["hideResult"] = choices.hideResult //when parameter is missing then the default is true, so only add it when it's false
			if (!choices.keyboard) generatedChoices["keyboard"] = choices.keyboard //when parameter is missing then the default is true, so only add it when it's false
			if (choices.ky) generatedChoices["ky"] = choices.ky
			if (choices.iTappAsendus) generatedChoices["iTappAsendus"] = choices.iTappAsendus
			if (!choices.tech) generatedChoices["tech"] = choices.tech

		}
		if (choices.langChange) generatedChoices["langChange"] = choices.langChange

		//SearchTypeChoice
		if (choices.search === 2) { //when "Select object types" is selected
			var searchLayers = []
			if (choices.ehak) searchLayers.push("EHAK")
			if (choices.territorial) searchLayers.push("VAIKEKOHT")
			if (choices.cadastral) searchLayers.push("KATASTRIYKSUS")
			if (choices.traffic) searchLayers.push("TANAV")
			if (choices.building) searchLayers.push("EHITISHOONE")
			generatedChoices["searchLayers"] = searchLayers
			if (choices.buildingUN) generatedChoices["unHoone"] = true
			if (choices.filters) generatedChoices["displayfilter"] = true

			if (choices.selectBuilding) generatedChoices["building"] = true
		}
		if (!choices.help) generatedChoices["help"] = false
		if (choices.displayGeom !== "marker_and_geometry") generatedChoices["displayGeom"] = choices.displayGeom
		if (!choices.displayGray) generatedChoices["displayGray"] = choices.displayGray //when parameter is missing then the default is true, so only add it when it's false
		//MapTypeChoice
		if (choices.baseLayers.length !== 0) {
			generatedChoices["defaultBaseLayer"] = choices.defaultBackground
			var baseLayers = []
			for (let layer of choices.baseLayers) {
				if (layer[1]) baseLayers.push(layer[0]) //e.g translates "map" to "ALUSKAART" and adds that to baseLayers list
			}
			generatedChoices["baseLayers"] = baseLayers
		}

		if (choices.ehakFilter !== "-1") generatedChoices["ehak"] = choices.ehakFilter.replace(/^0+/, '')
		if (choices.mapLayersAddress || choices.mapLayersCadastral) {
			var mapLayers = []
			if (choices.mapLayersAddress) mapLayers.push("AADRESS")
			if (choices.mapLayersCadastral) mapLayers.push("KATASTRIYKSUS")
			generatedChoices["mapLayers"] = mapLayers
		}
		generatedChoices["WMS"] = choices.wms

		//markers, if there are any added
		//markers are only added if mode is "4"
		if (choices.mode === 4) {
			if (choices.markers !== null) generatedChoices["markers"] = choices.markers
			if (choices.labelMode === 1) {
				generatedChoices["labelMode"] = "label";
			} else if (choices.labelMode === 2) {
				generatedChoices["labelMode"] = "tooltip";
			} else if (choices.labelMode === 3) {
				generatedChoices["labelMode"] = "label-tooltip";
			}
		}
	}

	if (choices.mode !== 3 && choices.mode !== 4) {
		if (choices.inputPosition) generatedChoices["inputPosition"] = 'top'
	}

	//Search input always present
	if (choices.mode !== 4) {
		if (choices.poi || choices.knr) {
			generatedChoices["poi"] = choices.poi
			generatedChoices["knr"] = choices.knr
			if (choices.knr && choices.maPiirkond) generatedChoices["maPiirkond"] = choices.maPiirkond
			generatedChoices["type"] = choices.type
		}
	}

	//SearchTypeChoice
	if (choices.apartment) { //nested if to determine correct value for "appartment" value
		if (choices.allowNewApartments) {
			generatedChoices["appartment"] = 2
		} else {
			generatedChoices["appartment"] = 1
		}
	} else {
		generatedChoices["appartment"] = 0
	}
	//language
	generatedChoices["lang"] = lang

	//return the code
	return 'var inAadress = new InAadress(' + JSON.stringify(generatedChoices) + ');';
}

export class CodeGeneratorResult extends Component {
	//using this.context will return the currently selected language code (eg. "et" or "en")
	static contextType = LanguageContext;

	constructor(props) {
		super(props);

		//generate the path dynamically
		let path
		if (window.REACT_APP_INADS_PATH_BASE_URL === "")
			path = window.location.origin + window.REACT_APP_INADS_PATH
		else
			path = window.REACT_APP_INADS_PATH_BASE_URL + window.REACT_APP_INADS_PATH;

		var code = generateCode(this.props.choicesMade, this.props.display.lang)

		var width = this.props.choicesMade.width + "px";
		var height = this.props.choicesMade.height + "px";

		if (this.props.choicesMade.fullscreen) width = height = "100%"

		var example = '<!DOCTYPE HTML>\n';
		example += '<html>\n';
		example += '	<head>\n';
		example += '		<title>In-ADS komponent</title>\n';
		example += '		<meta http-equiv="Content-Type" content="text/html; charset=utf-8">\n';
		if (this.props.choicesMade.fullscreen)
			example += '		<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"> \n';
		else
			example += '		<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">\n';
		example += '		<script type="text/javascript" src="' + path + '"></script>\n';
		example += '	</head>\n';
		example += '	<body>\n';
		example += '		<div id="' + this.props.choicesMade.container + '" style="width: ' + width + '; height: ' + height + '"></div>\n';
		example += '		<script>\n';
		example += '			' + code + '\n';
		example += '		</script>\n';
		example += '	</body>\n';
		example += '</html>';

		this.state = {
			"header": '<script type="text/javascript" src="' + path + '"></script>',
			"code": code,
			"example": example
		}
	}

	render() {
		const popupstyle = {
			borderRadius: 0,
			opacity: 0.9,
			padding: '1em',
			color: "#32774e"
		}

		return (
			<div>
				<Grid centered columns={1}>
					<Grid.Row>
						<Grid.Column>
							<div className="heading">
								<Divider horizontal>{translate("CodeGeneratorResult", "title", this.context)}</Divider>
							</div>
							<h4>{translate("CodeGeneratorResult", "header", this.context)}</h4>
							<div className="code">
								<div className="copy">
									<Popup
										content={translate("Buttons", "copied", this.context)}
										on='click'
										pinned
										style={popupstyle}
										position={'top right'}
										trigger={<Icon name="copy outline" onClick={() => { navigator.clipboard.writeText(this.state.header) }} />}
									/>
								</div>
								{this.state.header}
							</div>
							<h4>{translate("CodeGeneratorResult", "jspart", this.context)}</h4>
							<div className="code">
								<div className="copy">
									<Popup
										content={translate("Buttons", "copied", this.context)}
										on='click'
										pinned
										style={popupstyle}
										position={'top right'}
										trigger={<Icon name="copy outline" onClick={() => { navigator.clipboard.writeText(this.state.code) }} />}
									/>
								</div>
								{this.state.code}
							</div>
							<h4>{translate("CodeGeneratorResult", "example", this.context)}</h4>
							<div className="code">
								<div className="copy">
									<Popup
										content={translate("Buttons", "copied", this.context)}
										on='click'
										pinned
										style={popupstyle}
										position={'top right'}
										trigger={<Icon name="copy outline" onClick={() => { navigator.clipboard.writeText(this.state.example) }} />}
									/>
								</div>
								{this.state.example}
							</div>

							<p>{translate("CodeGeneratorResult", "moreinfo", this.context)}</p>

							<a className="manual ui-link" rel="noopener noreferrer" target="_blank" href={this.context === "et" ? UserPDFest : UserPDFeng}><Icon name="book" />{translate("Intro", "usermanual", this.context)}</a><br></br>
							<a className="devmanual ui-link" rel="noopener noreferrer" target="_blank" href={this.context === "et" ? DevPDFest : DevPDFeng}><Icon name="book" /> {translate("Intro", "devmanual", this.context)}</a><br></br>
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Grid centered columns={2}>
					<Grid.Row className="buttonRow button">
						<Grid.Column>
							<NavButton whereto={this.props.display.pageBeforeResult === undefined ? "/S3" : this.props.display.pageBeforeResult} icon="arrow left" text="back" />
						</Grid.Column>
						<Grid.Column>
							<NavButton whereto={"/"} icon="repeat" text="backtobeginning" />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		)
	}
}

export default CodeGeneratorResult
